body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Century Gothic', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Montserrat;
  src: url('/fonts/Montserrat-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Montserrat-Light;
  src: url('/fonts/Montserrat-Light.ttf');
  font-weight: 600;
}
