.appBox,
.appContainer {
  position: relative;
  height: 100%;
}

.warning {
  height: 15vh;
  width: 100%;
}

.warning_2 {
  height: 10vh;
  width: 100%;
}

.urbanpaceLabel {
  width: 156px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  top: -34px;
}

.atataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  height: 100vh;
  text-align: center;
}

.layersWrapper {
  z-index: 9999999;
}

.layersWrapper > div {
  z-index: 9999999;
  background-color: #2dd196;
  /*width: 100vw;*/
  height: 50px;
  position: fixed;
  left: 0;
  right: 0;
  background-image: url(/images/app-background.jpg);
  background-position-y: 55%;
}

.layersWrapper_2 > div {
  background-image: none;
  background-color: white;
}

.layersWrapper > div:nth-of-type(1) {
  top: 0;
  background-position-y: 55%;
}

.layersWrapper > div:nth-of-type(2) {
  bottom: 0;
  background-size: 217%;
  background-position-y: -39%;
  background-position-x: 92%;
}

.layersWrapper > img {
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.productImage {
  width: 100%;
}
