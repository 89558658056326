.directionBtn {
  width: 48px;
  position: fixed;
  bottom: 50px;
  right: -1px;
  padding: 0;
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
  animation-name: nexBtnSlide;
  animation-duration: 1.2s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  z-index: 99999;
}

.arrow {
  margin-left: 10px;
}

.directionBtn.back {
  position: fixed;
  top: 20px;
  bottom: initial;
  left: -1px;
  right: initial;
  animation-name: initial;
}

.btnSvg {
  width: 100%;
  height: auto;
  fill: #ffffff;
  background-color: transparent;
}

.btnSvg.back {
  transform: scaleX(-1);
}

.skip-video.bigB.directionBtn {
  right: 0;
  bottom: 0;
  width: 100px;
  animation: none;
  cursor: pointer;
}

.skip-video.bigB.directionBtn > .name {
  display: inline-flex;
  align-items: center;
  color: #0c3675;
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  top: 58px;
  left: 16px;
  transform: none;
}

.skip-video-opacity.bigB.directionBtn {
  right: 0;
  bottom: 0;
  width: 100px;
  animation: none;
  cursor: pointer;
}

.skip-video-opacity.bigB.directionBtn > .name {
  display: inline-flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  top: 58px;
  left: 16px;
  transform: none;
}

.name {
  position: absolute;
  top: 50%;
  left: 13px;
  color: #0674e2;
  transform: translate(0, -50%);
  font-size: 10px;
}

.name.back {
  left: initial;
  right: 13px;
}

@keyframes nexBtnSlide {
  0% {
    transform: translateX(20%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(20%);
  }
}

.bigB.directionBtn {
  width: 80px;
}
.bigB.back {
  width: 50px;
}
.bigB .name {
  font-size: 20px;
}
.bigB.back .name {
  font-size: 11px;
}
