.desktopBunner {
  background-image: url("/images/app-background.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100%;
  position: relative;
}

.desktopBunnerWr {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
}

.hellowTitle {
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}

.hellowMessage {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  margin-top: 34px;
}

.complitedTitle {
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}

.complitedImage {
  position: absolute;
  top: -12%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  width: 60px;
  width: auto;
  height: 30%;
}

.complitedImageBig {
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  width: 60px;
  width: auto;
  height: 50%;
}

.sessionEndText {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.notConfirmedImage {
  width: 230px;
}

.notConfirmedMessage {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.notConfirmedTitle {
  margin-top: 34px;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}

.desktopTitle {
  font-weight: bold;
  font-size: 55px;
  line-height: 64px;
  text-align: center;
  color: #ffffff;
}

.desktopMessage {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  margin-top: 28px;
}

.bunnerFire {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.bunnerFire .desktopBunnerWr {
  width: 100%;
  height: 100%;
  /* background: #222; */
}

.animImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: auto;
  height: 120%;
}

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.circle {
  display: block;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 300px;
  margin: -150px 0 0 -150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9370db;
  animation: spin 2s linear infinite;
}

.circle:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ba55d3;
  animation: spin 3s linear infinite;
}

.circle:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff00ff;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noMobileView {
  width: 100vw;
  height: 100vh;
  background-image: url("/images/app-background.png");
  background-size: cover;
  background-position: center;
  min-height: 100%;
  background-color: #92dfff;
  display: flex;
  align-items: center;
  justify-content: center;

  .contentContainer {
    width: 100%;
    height: max-content;
    position: relative;
    text-align: center;

    span {
      display: block;
      color: #0c3675;
      font-family: Roboto, sans-serif;
      z-index: 2;
      position: relative;

      &:first-of-type {
        text-transform: uppercase;
        font-size: 50px;
        line-height: 55px;
        font-weight: 700;
      }

      &:last-of-type {
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
        max-width: 240px;
        margin: 10px auto 0;
      }
    }

    .phone {
      width: 100px;
      z-index: 2;
      position: relative;
    }

    .lines {
      position: absolute;
      top: 50%;
      left: 0;
      height: 160px;
      width: 100%;
    }

    .circleNoMobile {
      position: absolute;
      top: 0;
      left: 50%;
      width: 302px;
      height: 302px;
      transform: translate(-50%, -17%);
    }
  }
}
