.videoWr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

@keyframes nexBtnSlide {
  0% {
    transform: translateX(20%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(20%);
  }
}
