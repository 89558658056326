.footerContainer {
  width: 100%;
  height: 100px;
  padding: 10px 3px;
  background-color: white;
  overflow: hidden;
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 96%;
    text-transform: uppercase;
    letter-spacing: -0.68px;
  }
}
