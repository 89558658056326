.playerWr {
  width: 100%;
  position: relative;
  height: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player {
  height: 100%;
  width: 100%;
  position: relative;
}

.player::-webkit-media-controls {
  display: none !important;
}

.playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  display: none;
  align-items: center;
  justify-content: center;
  outline: none;
}

.pauseBtnAnimation {
  pointer-events: none;
  animation-name: pause;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.pauseBtnVisible {
  display: flex;
}

.playBtnImg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 10px solid rgb(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.playBtnImg::before {
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left: 16px solid rgb(255, 255, 255, 0.7);
  position: relative;
  left: 7px;
}

.pauseBtnImg {
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pauseBtnImg::before {
  content: '';
  width: 7px;
  height: 20px;
  background-color: rgb(255, 255, 255, 0.7);
  margin-right: 7px;
}

.pauseBtnImg::after {
  content: '';
  width: 7px;
  height: 20px;
  background-color: rgb(255, 255, 255, 0.7);
}

@keyframes pause {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.trayBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.7);
}

.trayPlayBtn {
  width: 71px;
  height: 50px;
  flex: 0 0 71px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trayPauseBtn {
  width: 71px;
  height: 50px;
  flex: 0 0 71px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trayPauseBtn::before {
  content: '';
  width: 4px;
  height: 15px;
  background-color: #ffffff;
  margin-right: 7px;
}

.trayPauseBtn::after {
  content: '';
  width: 4px;
  height: 15px;
  background-color: #ffffff;
}

.trayPlayBtn::before {
  content: '';
  width: 0px;
  height: 0px;
  border: 8px solid transparent;
  border-left: 16px solid #ffffff;
  position: relative;
  left: 7px;
}

.timer {
  padding: 0px 10px;
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.timerBg {
  flex-grow: 1;
  width: 100%;
  height: 4px;
  background-color: #585858;
  position: relative;
}

.timerPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  background-color: #2dd196;
}

.timerTime {
  margin-left: 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  flex-shrink: 0;
}

.volume {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #ffffff;
  outline: none;
}

.fullScreen {
  width: 30px;
  margin-right: 10px;
  color: #ffffff;
  outline: none;
}
