.container {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;

  .content {
    text-align: center;
    box-sizing: border-box;
    background-color: #7cbee4;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

    .headLogo {
      width: 246px;
      position: relative;
      z-index: 1;
      margin-top: 38px;
    }

    .carousel {
      margin-top: 35px;

      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        width: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 180px;
        height: auto;
        object-fit: cover;
      }

      .packItem {
        width: 180px;
        height: 316px;
      }

      .inactiveSlide {
        transform: scale(0.7);
        transition: transform 0.5s;
      }

      .activeSlide {
        transform: scale(1);
        transition: transform 0.5s;
      }
    }

    .characteristicContainer {
      padding: 0 10px;
      margin-top: 20px;

      img {
        width: 100%;
        position: relative;
        z-index: 1;

        &:not(:first-of-type) {
          margin-top: -10px;
        }
      }
    }

    .buyContainer {
      padding: 0 30px;
      margin-top: 10px;

      .content {
        border-radius: 10px;
        background: rgba(9, 0, 65, 0.3);
        backdrop-filter: blur(3.5px);
        padding: 20px 0;

        .title {
          color: #fdfdfd;
          text-align: center;
          font-family: Roboto, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
        }

        .description {
          color: #fff;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          padding: 0 17px;
          margin-top: 14px;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

          span {
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            font-weight: 700;
          }
        }

        .buttonContainer {
          margin-top: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 13px;
          padding: 0 21px;

          button {
            width: 100%;
            padding: 14px 0;
            height: 53px;
            border-radius: 10px;
            text-align: center;
            color: #fff;
            font-family: Roboto, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            letter-spacing: -0.72px;
            text-transform: uppercase;

            &:first-child {
              background: linear-gradient(
                rgba(183, 50, 255, 1),
                rgba(122, 31, 171, 1)
              );
            }

            &:last-child {
              background: linear-gradient(
                rgba(50, 211, 58, 1),
                rgba(17, 131, 23, 1)
              );
            }
          }
        }
      }
    }
  }

  .footer {
    background-color: #e0eaf3;
    padding: 41px 13px;
    color: #000;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 4.2px;
    text-transform: uppercase;
  }

  .unsubscribe {
    padding: 5px 20px 15px;
    color: #000;
    text-align: center;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;

    a {
      color: #000;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}
